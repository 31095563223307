import React, { useState, useEffect } from 'react';
import {
  Card, ListGroup, Button, Row, Col, Alert,
} from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setPlan, selectSignUp } from '../features/signup/signupSlice';
import {
  Campaign,
  selectCampaign,
} from '../features/campaign/campaignSlice';
import {
  Discount,
  discountWording,
  selectDiscount,
} from '../features/discount/discountSlice';
import {
  Coupon,
} from '../features/coupon/couponAPI';
import {
  GetMonthlyCost,
  getDiscountPlanCost,
} from './OrderSummary';
import { Plan } from '../models/plans';
import ScrollIndicator from './ScrollIndicator';
import ScrollStack from './ScrollStack';
import PineappleAlert from './PineappleAlert';
import { humanizeNumber } from '../lib/utils';
import MostPopularPlansSwitch from './MostPopularPlansSwitch';

const MostPopular = () => (
  <div className="position-relative">
    <div
      style={{
        borderWidth: "0.3em",
        borderStyle: "solid",
        borderColor: "primary !important",
        top: '-2.4em',
        right: '-1em',
        zIndex: 999,
        backgroundColor: "black",
      }}
      className="d-flex
        align-items-center
        align-middle
        rounded-pill
        p-4
        bg-black
        border-black
        w-auto
        position-absolute"
    >
      <span
        className="text-warning
          lh-1
          fs-5
          fw-bold
          position-absolute
          text-center
          start-0"
      >
        BLACK FRIDAY
        {' '}
        <strong style={{ color: "white" }}>SALE</strong>
      </span>
    </div>
  </div>
);

interface CampaignOfferProps {
  campaign: Campaign;
}

const CampaignOffer = ({ campaign }: CampaignOfferProps) => {
  const { monthsFree: n, terms, referral } = campaign;
  const icon = (terms !== '') ? '⁺' : '';
  const boldCopy = (n !== undefined)
    ? `Plus ${humanizeNumber(n)} ${n > 1 ? 'months' : 'month'} FREE${icon}`
    : 'Special Promotional Pricing';

  const supportingCopy = (n !== undefined)
    ? 'applies to any of the below plans'
    : 'applies in your building';

  return (
    <div className="d-flex justify-content-center">
      <PineappleAlert className="d-inline-block px-3">
        { referral && campaign.id === "SALES_REFERRAL" && (
        <>
          <strong>
            One Month FREE
            {icon}
          </strong>
          {' '}
          applies to any of the below plans.
        </>
        ) }
        { referral && campaign.id !== "SALES_REFERRAL" && (
        <>
          Enjoy
          {' '}
          <strong>
            One Month FREE
            {icon}
          </strong>
          {' '}
          thanks to your neighbour. One
          Month FREE applies to any of the below plans.
        </>
        ) }
        { !referral && (
        <>
          <strong className="text-capitalize">{ boldCopy }</strong>
          {' '}
          { supportingCopy }
          <br />
          <span className={campaign.moveIn ? 'd-none' : ''}>
            Hurry, offer ends
            {' '}
            { dayjs(campaign.endDate).format('DD/MM/YYYY') }
          </span>
        </>
        )}
      </PineappleAlert>
    </div>
  );
};

interface DiscountOfferProps {
  discount: Discount;
  coupon?: Coupon;
}

const DiscountOffer = ({ discount, coupon }: DiscountOfferProps) => {
  const words = discountWording(discount, true);
  const icon = (discount.terms !== '' && discount.terms !== undefined) ? '⁺' : '';

  if (words === "") return null;
  if (coupon?.coupon_id === "PNBF24") return null;

  return (
    <div className="d-flex justify-content-center">
      <PineappleAlert className="d-inline-block px-3">
        <strong>
          {words}
          {icon}
          { coupon?.coupon_id && ` using promo code ${coupon.coupon_id}` }
          { discount.period !== 0 && ". Prices as marked." }
        </strong>
      </PineappleAlert>
    </div>
  );
};

interface PlanProps {
  plans: Record<string, Plan>
  className?: string
}

const Plans = ({ className = '', plans }: PlanProps) => {
  const dispatch = useAppDispatch();
  const signUpState = useAppSelector(selectSignUp);
  const campaign = useAppSelector(selectCampaign);
  const discount = useAppSelector(selectDiscount);

  const plansState: Record<string, boolean> = Object
    .keys(plans)
    .reduce((acc, name) => ({
      ...acc,
      [name]: signUpState.plan.name === name,
    }), {});

  const sortedPlans = Object.values(plans)
    .sort((p1, p2) => p1.speed - p2.speed);

  const defaultPlan = () => sortedPlans
    .find((plan) => plan.name === 'Boost 100');

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const [
    buttonState,
    setButtonState,
  ] = useState<Record<string, boolean>>(plansState);
  const setChange = (plan: string) => {
    Object.keys(buttonState).forEach((planName) => {
      buttonState[planName] = false;
    });
    buttonState[plan] = !buttonState[plan];
    setButtonState({
      ...buttonState,
    });
    setSelectedIndex(sortedPlans.map((p) => p.name).indexOf(plan));
    dispatch(setPlan(plans[plan]));
  };

  // If the default plan changes such as through loading a different set of
  // sorted plans, trigger this effect again
  useEffect(() => {
    const p = defaultPlan();
    if (p !== undefined) setChange(p.name);
  }, [defaultPlan()]);

  const [mostPopular, setMostPopular] = useState(true);

  console.log("sorted plans length", sortedPlans.length);

  return (
    <div className={className}>

      { campaign.id && <CampaignOffer campaign={campaign} />}
      { !campaign.id && (
      <DiscountOffer
        coupon={signUpState.coupon}
        discount={discount}
      />
      ) }

      {sortedPlans.length > 1 && <MostPopularPlansSwitch options={["All Plans", "Most Popular"]} onChange={() => setMostPopular(!mostPopular)} checked={mostPopular} />}
      <ScrollStack
        className="hide-scrollbar
          px-2
          py-4
          overflow-scroll
          d-flex
          align-items-stretch
          "
        gap={2}
        direction="horizontal"
        mostPopular={mostPopular}
        initialPosition={mostPopular ? "auto" : "offset-1"}
      >
        {(sortedPlans.length > 1 ? (sortedPlans.filter((plan) => ["streamer", "boost"].includes(plan.name.toLowerCase()) || !mostPopular)) : sortedPlans).flatMap((plan) => (
          <Card
            key={plan.id}
            className="pineapple-card shadow h-100"
            border="grey"
          >
            {/* (plan.id === 'pa-150mbps') && <MostPopular /> */}
            <Card.Body>
              <Card.Title
                className="
                  text-center
                  text-primary
                  border-bottom
                  border-primary
                  fs-3
                  pb-2
                  mb-0"
              >
                {plan.name}
                <br />
                {plan.speed}
                /
                {plan.speed}
                {' '}
                Mbps *
              </Card.Title>
              <ListGroup
                className="border-bottom border-primary py-2"
                variant="flush"
              >
                <ListGroup.Item
                  className="border-0 p-0 position-relative align-middle"
                >
                  <CheckCircleFill
                    style={{
                      backgroundColor: '#03793E',
                      borderRadius: '1em',
                    }}
                    className="
                      position-absolute
                      start-0
                      top-0
                      bottom-0
                      m-auto"
                    color="#F5E748"
                  />
                  <strong
                    className="ms-3"
                  >
                    No lock in contract
                  </strong>
                </ListGroup.Item>
                <ListGroup.Item
                  className="border-0 p-0 position-relative align-middle"
                >
                  <CheckCircleFill
                    style={{
                      backgroundColor: '#03793E',
                      borderRadius: '1em',
                    }}
                    className="
                      position-absolute
                      start-0
                      top-0
                      bottom-0
                      m-auto"
                    color="#F5E748"
                  />
                  <strong
                    className="ms-3"
                  >
                    Unlimited data
                  </strong>
                </ListGroup.Item>
                <ListGroup.Item
                  className="border-0 p-0 position-relative align-middle"
                >
                  <CheckCircleFill
                    style={{
                      backgroundColor: '#03793E',
                      borderRadius: '1em',
                    }}
                    className="
                      position-absolute
                      start-0
                      top-0
                      bottom-0
                      m-auto"
                    color="#F5E748"
                  />
                  <strong
                    className="ms-3"
                  >
                    Equal up and download speeds
                  </strong>
                </ListGroup.Item>
              </ListGroup>
              <Card.Text className="text-center py-2 mb-0" />
              <Card.Text className="text-center">
                <span
                  className="
                  display-1
                  text-primary
                  text-center
                  lh-1
                  d-block"
                >
                  $
                  {discount?.period !== 0 ? getDiscountPlanCost(GetMonthlyCost(plan), discount) : plan.cost}
                  <span
                    className="text-primary text-center align-top display-3"
                  >
                    ^
                  </span>
                </span>
                <span className="fs-6">per month</span>
                <br />
                <span
                  className="fs-6"
                >
                  {plan.description1
                  && plan.description1 !== undefined
                  && plan.description1 !== '  '
                    ? plan.description1
                    : <span>&nbsp;&nbsp;</span>}
                </span>
                {/* {plan.id === "pa-150mbps" && (
                <PineappleAlert classOverride="text-white bg-purple my-2 text-center rounded-pill">
                  <div style={{
                    paddingLeft: 45,
                    paddingRight: 45,
                    fontSize: 20,
                  }}
                  >
                    Use code
                    <strong className="fs-5 text-warning">
                      {' '}
                      PNBF24
                      {' '}
                    </strong>
                    to get for
                    <strong>
                      {' '}
                      $49
                      {' '}
                      <span
                        className="text-white text-center align-top"
                      >
                        *
                      </span>
                    </strong>
                  </div>
                </PineappleAlert>
                )} */}
              </Card.Text>
              <Button
                id={`select_plan_${plan.name}`}
                className="w-100"
                size="lg"
                onClick={() => setChange(plan.name)}
                variant={buttonState[plan.name] ? 'secondary' : 'primary'}
              >
                { buttonState[plan.name] ? 'Selected' : 'Select' }
              </Button>
            </Card.Body>
          </Card>
        ))}
      </ScrollStack>
      <Row className="justify-content-sm-center">
        <Col sm md={4} className="justify-content-center d-flex">
          <ScrollIndicator
            activeIndex={selectedIndex}
            length={mostPopular ? 3 : Object.values(plans).length}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Plans;
